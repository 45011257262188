import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { itCourses, productData } from "../constant/Product"
import CourseCard from "./CourseCard"
import { popularCourse } from "../constant/popularCourse"


const SingleProduct = () => {
    const [data, setData] = useState({})

    const { id } = useParams()


    useEffect(() => {
        const findProduct = () => {
            let product = productData.find(ele =>
                ele.id === id
            )
            if (product) {
                return product
            };

            product = popularCourse.find(ele => ele.id === id)
            if (product) {
                return product
            };
            product = itCourses.find(ele => ele.id === id)
            if (product) {
                return product
            }
            return null
        }
        let matchedIds = findProduct();
        setData(matchedIds);
    }, [id])

    return (
        <div >

            <div className='mt-5  max-md:mt-[300px]'>
                {
                    <div className="mt-5 mb-3 ">
                        {/* <CourseCard id={data.id} image={data.image}
                            courseName={data.courseName} desc={data.desc}
                            rating={data.rating}
                            enrolled={data.enrolled}
                            price={data.price}
                            offPrice={data.offPrice}
                        /> */}
                        <div className="w-full h-screen">
                            <img src={data.image} alt="course-img" className="h-full w-full object-fill" />
                            <div className="absolute bottom-0 left-0 right-0 flex justify-center items-center">
                                <div className="p-2 rounded-md" style={{ backgroundColor: " rgb(83 67 67 / 46%)" }}>
                                    <div>
                                    <p className="text-white text-3xl font-bold text-center">{data.courseName}</p>
                                    </div>
                                   
                                    <div >
                                        <p className="text-base text-white text-center">{data.desc}</p>
                                    </div>
                                    <div className="text-center">
                                        <button className="w-48 h-10 text-[16px] font-semibold bg-red-600 rounded-full text-white">Buy Course ₹{data.price}</button>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="mt-5 mb-5 w-[60%] mx-auto">
                            {
                                data.descriptionData || data.description ?
                                    <p className="text-2xl font-medium text-black text-center">Course description</p>
                                    :
                                    null
                            }

                            <div>
                                <p className="mt-2 text-base leading-6">
                                    {
                                        data.descriptionData &&
                                        data.descriptionData
                                    }
                                </p>

                                {
                                    data?.description?.details && (
                                        <div className="text-base">
                                            <p className="mt-2 text-base leading-6">
                                                {
                                                    data?.description?.details
                                                }
                                            </p>
                                            <p className="mt-2 text-base font-medium">{data.description.points}</p>
                                            <ul className="list-disc space-y-2">
                                                {data.description.key_points.map((ele, index) => (
                                                    <li key={index} className="text-gray-700 list-disc">{ele}</li>
                                                ))}
                                            </ul>
                                            <p className="mt-2 text-base leading-6">{data.description.end_title}</p>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default SingleProduct