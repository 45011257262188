const Terms=()=>{

    return (
        <div className="container">
            <div className="terms_box  mt-[120px]">
                <h3 className="font-bold">Terms of Service</h3>
                <div  className="mt-5">
                    <h5>1. Introduction</h5>
                    <p className="text-base font-[400]">
                    Welcome to ed.tech. These Terms of Service ("Terms") govern your access to and use of our website and services. By accessing or using our Website, you agree to be bound by these Terms. If you do not agree to these Terms, you should not use our Website.
                    </p>
                </div>
                <div  className="mt-5">
                    <h5>2. Eligibility</h5>
                    <p className="text-base font-[400]">
                    To use our Website, you must be at least 18 years old or have reached the age of majority in your jurisdiction, and have the legal capacity to enter into a binding agreement. If you are using the Website on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms.
                    </p>
                </div>
                <div  className="mt-5">
                    <h5>3. User Accounts</h5>
                    <ul className="text-base font-[400]">
                        <li><span className="font-[600]">Account Creation:</span> To access certain features, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process.</li>
                        <li className="mt-2"><span className="font-[600]">Account Security:</span> You are responsible for maintaining the confidentiality of your account login information and are fully responsible for all activities that occur under your account.</li>
                        <li className="mt-2"><span className="font-[600]">Account Termination:</span> We reserve the right to suspend or terminate your account at our sole discretion, without prior notice, for any reason, including violation of these Terms.</li>
                    </ul>
                </div>
                <div  className="mt-5">
                    <h5>4. User Conduct</h5>
                    <p className="text-base font-[400]">
                    You agree not to use the Website to:
                    </p>
                    <ul className="text-base font-[400]">
                        <li>Engage in any unlawful or harmful activity.</li>
                        <li className="mt-2">Post or share any content that is offensive, defamatory, or violates the rights of others.</li>
                        <li className="mt-2">Interfere with or disrupt the operation of the Website.</li>
                    </ul>
                </div>
                <div  className="mt-5">
                    <h5>5. Intellectual Property</h5>
                    <ul>
                        <li><span className="font-[600]">Ownership:</span> All content, trademarks, and other intellectual property on the Website are owned by or licensed to ed.tech unless otherwise stated.</li>
                        <li className="mt-2"><span className="font-[600]">Limited License:</span> You are granted a limited, non-exclusive, non-transferable license to access and use the Website for personal, non-commercial purposes.</li>
                        <li className="mt-2"><span className="font-[600]">Restrictions:</span> You may not copy, modify, distribute, or create derivative works based on the content on the Website without our express written permission.</li>
                    </ul>
                </div>
                <div  className="mt-5">
                    <h5>6. Content Submission</h5>
                    <ul>
                        <li><span className="font-[600]">User Content:</span>You retain ownership of any content you submit to the Website. By submitting content, you grant us a worldwide, royalty-free, and non-exclusive license to use, reproduce, and distribute your content in connection with the Website.</li>
                        <li className="mt-2"><span className="font-[600]">Content Guidelines:</span> You agree that any content you submit will comply with our content guidelines and will not infringe the rights of others.</li>
                    </ul>
                </div>
                <div  className="mt-5">
                    <h5>7. Disclaimers</h5>
                    <ul>
                        <li><span className="font-[600]">No Warranty:</span> The Website is provided on an "as is" and "as available" basis. We make no warranties, expressed or implied, regarding the operation or availability of the Website.</li>
                        <li className="mt-2"><span className="font-[600]">Limitation of Liability:</span> To the fullest extent permitted by law, ed.tech shall not be liable for any damages arising out of or in connection with your use of the Website.</li>
                    </ul>
                </div>
                <div  className="mt-5">
                    <h5>8. Indemnification</h5>
                    <p className="text-base font-[400]">
                    You agree to indemnify and hold ed.tech harmless from any claims, damages, or expenses arising out of your use of the Website or violation of these Terms.
                    </p>
                </div>
                <div  className="mt-5">
                    <h5>9. Changes to Terms</h5>
                    <p className="text-base font-[400]">
                    We reserve the right to update or modify these Terms at any time. We will notify you of any changes by posting the new Terms on the Website. Your continued use of the Website after any changes constitutes your acceptance of the new Terms.
                    </p>
                </div>
                <div  className="mt-5">
                    <h5>10. Governing Law</h5>
                    <p className="text-base font-[400]">
                    These Terms shall be governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these Terms shall be resolved in the courts of India.
                    </p>
                </div>
                <div  className="mt-5 mb-5">
                    <h5>11. Contact Information</h5>
                    <p className="text-base font-[400]">
                    If you have any questions or concerns about these Terms, please contact us at +91 8059465353.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Terms