const PrivacyPolicy = () => {


    return (
        <div className="container">
            <div className="privacy_box mt-[120px]">
                <h3 className="font-bold">Privacy Policy</h3>
                <div className="mt-5">
                    <h5>1. Introduction</h5>
                    <p className="text-base font-[400]">
                        At ed.tech, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you visit our website and use our services. By accessing our Website, you agree to the terms of this Privacy Policy.
                    </p>
                </div>
                <div className="mt-5">
                    <h5>2. Information We Collect</h5>
                    <p className="text-base font-[400]">
                        We may collect the following types of information:
                    </p>
                    <ul>
                        <li><span className="font-[600]">Personal Information:</span> This includes details such as your name, email address, phone number, and any other information you provide when creating an account, signing up for newsletters, or contacting us.</li>
                        <li className="mt-2"><span className="font-[600]">Usage Data: </span>We collect information about how you interact with our Website, including your IP address, browser type, pages visited, and the time spent on each page.</li>
                        <li className="mt-2"><span className="font-[600]">Cookies and Tracking Technologies:</span>We use cookies and similar technologies to enhance your experience on our Website. Cookies help us understand user preferences and improve our services. You can control cookie preferences through your browser settings.</li>
                    </ul>
                </div>
                <div className="mt-5">
                    <h5>3. How We Use Your Information</h5>
                    <p className="text-base font-[400]">
                        We use your information to:
                    </p>
                    <ul className="text-base font-[400]">
                        <li>Provide and improve our services.</li>
                        <li className="mt-2">Communicate with you regarding updates, promotions, or changes to our services.</li>
                        <li className="mt-2">Personalize your experience on our Website.</li>
                        <li className="mt-2">Analyze website usage to improve functionality and user experience.</li>
                        <li className="mt-2">Comply with legal obligations and protect our legal rights.</li>
                    </ul>
                </div>
                <div className="mt-5">
                    <h5>4. Data Sharing and Disclosure</h5>
                    <p className="text-base font-[400]">
                        We do not sell or share your personal information with third parties for marketing purposes. However, we may share your information with:
                    </p>
                    <ul>
                        <li><span className="font-[600]">Service Providers: </span>We may share your information with trusted third-party service providers who assist us in operating our Website and delivering services to you. These providers are obligated to protect your information and use it solely for the purposes we specify.</li>
                        <li className="mt-2"><span className="font-[600]">Legal Requirements: </span>We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
                        <li className="mt-2"><span className="font-[600]">Business Transfers: </span>In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of that transaction.</li>
                    </ul>
                </div>
                <div className="mt-5">
                    <h5>5. Data Security</h5>
                    <p className="text-base font-[400]">
                        We take data security seriously and implement appropriate technical and organizational measures to protect your personal information from unauthorized access, alteration, or disclosure. However, please note that no method of transmission over the Internet or electronic storage is 100% secure.
                    </p>
                </div>
                <div className="mt-5">
                    <h5>6. Your Rights</h5>
                    <p className="text-base font-[400]">
                        You have the following rights regarding your personal information:
                    </p>
                    <ul>
                        <li><span className="font-[600]">Access: </span> You have the right to request access to the personal information we hold about you.</li>
                        <li className="mt-2"><span className="font-[600]">Correction:</span> You can request that we correct any inaccuracies in your personal information.</li>
                        <li className="mt-2"><span className="font-[600]">Deletion:</span>You have the right to request that we delete your personal information, subject to certain exceptions.</li>
                        <li className="mt-2"><span className="font-[600]">Opt-Out:</span>You can opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in the emails.</li>
                    </ul>
                </div>
                <div className="mt-5">
                    <h5>7. Children’s Privacy</h5>
                    <p className="text-base font-[400]">
                        Our Website is not intended for use by children under the age of 13, and we do not knowingly collect personal information from children. If you are a parent or guardian and believe your child has provided us with personal information, please contact us, and we will take steps to delete such information.
                    </p>
                </div>
                <div className="mt-5">
                    <h5>8. International Data Transfers</h5>
                    <p className="text-base font-[400]">
                        If you are accessing our Website from outside India, please be aware that your information may be transferred to, stored, and processed in [Your Country], where our servers are located. By using our Website, you consent to the transfer of your information to our servers and the processing of your information in accordance with this Privacy Policy.
                    </p>
                </div>
                <div className="mt-5">
                    <h5>9. Changes to This Privacy Policy</h5>
                    <p className="text-base font-[400]">
                        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated policy on our Website and updating the "Last Updated" date at the top of this page. Your continued use of the Website after any changes constitutes your acceptance of the new Privacy Policy.
                    </p>
                </div>
                <div className="mt-5 mb-5">
                    <h5>10. Contact Us</h5>
                    <p className="text-base font-[400]">
                        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at +91 8979287631.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy