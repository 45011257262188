import { createContext, useState } from "react"

export const searchContext = createContext()

const ContextProvider = ({ children }) => {
    const [searchValue, setSearchValue] = useState('');



    return (

        <searchContext.Provider value={{ searchValue, setSearchValue }}>
            {children}
        </searchContext.Provider>
    )
}

export default ContextProvider


