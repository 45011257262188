import "./styles/CourseSlide.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import "../Components/styles/Main.css"
import { useContext, useEffect, useState } from "react";
import { searchContext } from "../Components/ContextProvider";
import {  productData, itCourses } from "../constant/Product"
import CourseCard from "../Components/CourseCard";


const Courses = ({ selectTab }) => {
    const [filteredProducts, setFilteredProducts] = useState(productData);
    const { searchValue } = useContext(searchContext)


    useEffect(() => {
        let courseData;
        switch (selectTab) {
            case "Hacking":
                courseData = productData;
                break;
            case "It":
                courseData = itCourses;
                break;
            default:
                courseData = productData;
        }

        const filteredData = courseData.filter((product) => {
            const searchTerm = searchValue.toLowerCase();
            const courseNameLower = product.courseName.toLowerCase();
            return courseNameLower.includes(searchTerm);
        });
        setFilteredProducts(filteredData);
    }, [searchValue, selectTab]);


    return (
        <div className="slide_section pb-3">
            <div className="container mb-3" >
                {/* <div className="course-items d-flex justify-content-between">
                    {
                        CoursesName.map((ele) => (
                            <div className="course-item" key={ele.id}>
                                <ul >
                                    <li>{ele.course}</li>
                                    <li>{ele.learners}</li>
                                </ul>
                            </div>
                        ))
                    }
                </div> */}
                <div className='course-slider-section mt-5'>

                    <Swiper
                        pagination={{
                            type: 'progressbar',
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {/* swiper-slide-first */}

                        <SwiperSlide>
                            <div className="popolar-courses">
                                <div className='mt-3 d-flex justify-content-between max-sm:flex-col max-sm:gap-y-5'>
                                    {filteredProducts.length > 0 ? (
                                        filteredProducts.slice(0, 4).map((product) => (
                                            <CourseCard id={product.id} image={product.image}
                                                courseName={product.courseName} desc={product.desc}
                                                rating={product.rating}
                                                enrolled={product.enrolled}
                                                price={product.price}
                                                offPrice={product.offPrice}
                                            />
                                        ))
                                    ) : (
                                        <p>No courses found matching your search.</p>
                                    )}

                                </div>
                            </div>
                        </SwiperSlide>

                        {/* swiper-slide-second*/}

                        <SwiperSlide>
                            <div className="popolar-courses">
                                <div className='mt-3 d-flex justify-content-between max-sm:flex-col max-sm:gap-y-5'>
                                    {filteredProducts.length > 0 ? (
                                        filteredProducts.slice(4, 8).map((product) => (
                                            <CourseCard id={product.id} image={product.image}
                                                courseName={product.courseName} desc={product.desc}
                                                rating={product.rating}
                                                enrolled={product.enrolled}
                                                price={product.price}
                                                offPrice={product.offPrice}
                                            />
                                        ))
                                    ) : (
                                        <p>No courses found matching your search.</p>
                                    )}

                                </div>
                            </div>
                        </SwiperSlide>

                        {/* swiper-slide-third*/}
                            
                        <SwiperSlide>
                            <div className="popolar-courses">
                                <div className='mt-3 d-flex justify-content-between card-section max-sm:flex-col max-sm:gap-y-5'>
                                    {filteredProducts.length > 0 ? (
                                        filteredProducts.slice(8).map((product) => (
                                            <CourseCard id={product.id} image={product.image}
                                                courseName={product.courseName} desc={product.desc}
                                                rating={product.rating}
                                                enrolled={product.enrolled}
                                                price={product.price}
                                                offPrice={product.offPrice}
                                            />
                                        ))
                                    ) : (
                                        <p>No courses found matching your search.</p>
                                    )}
                                </div>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Courses