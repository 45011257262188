import { useEffect, useState } from "react";
import { CoursesName, itSubCourses } from "../constant/Product";
import "./styles/CourseSlide.css"
import Courses from "./Courses";

const SubCourses = ({myTab}) => {
    const [showTab, setShowTab] = useState(CoursesName[0].course);
    let [courseData,setCourseData] = useState([]);

    useEffect(()=>{
        switch(myTab){
            case "hacking":
                setCourseData(CoursesName);
                setShowTab(CoursesName[0].course)
                break;
            case "itcertifications":
                setCourseData(itSubCourses);
                setShowTab(itSubCourses[0].course)
                break;  
            default:
               setCourseData(CoursesName)  
               setShowTab(CoursesName[0].course)
        } 

    },[myTab])

    
    

    return (
       <section className="container sub_courses" style={{paddingTop:"30px"}}>
        <div className="flex gap-4 max-lg:flex-wrap max-sm:gap-y-2.5 max-sm:gap-x-[2px]"> 
            {
                courseData.map((ele)=>(
                    <div  
                    className={`course-item cursor-pointer ${showTab === `${ele.course}` ? "active" : ""}`}
                    data-bs-toggle="tab"
                    role="tab"
                    onClick={() => setShowTab(ele.course)}
                    key={ele.id}>
                    <ul  id="myTab">
                        <li>
                            {ele.course}
                        </li>
                        {/* <li>
                            {ele.learners}
                        </li> */}
                    </ul>
                    </div>
                ))
            } 
       
        </div>  
        <div className="tab-content" id="myTabContent">
            {
                courseData.map((ele)=>(
                    <div
                    className={`tab-pane fade ${
                        showTab === `${ele.course}` && "show active"
                      } `}
                      role="tabpanel"
                      style={{background:"white",color:"black"}}
                    >
                        <Courses selectTab={ele.course}/>
                    </div>
                ))
            }
        </div>
       </section>
    )
}
export default SubCourses