import "./styles/Footer.css"
import { Link } from "react-router-dom"

const Footer = () => {

    return (
        <section className="">
            <footer className="footer mt-auto w-[100%]">
                    <div className="container pt-5 text-left pb-5 flex justify-center items-center">
                        <div className="flex flex-wrap gap-x-[180px]">
                            <div className="">
                                    <h3 className="footer-heading">About us</h3>
                                    <ul className="footer-nav ">
                                        {/* <li>Business</li>
                                        <li>Customer Support</li> */}
                                        <li><Link to="/about" style={{textDecoration:"none",color:"white"}}>About Us</Link></li>
                                        {/* <li>Get the app</li> */}
                                        <li><Link to="/contact" style={{textDecoration:"none",color:"white"}}>Contact us</Link></li>
                                    </ul>
                                
                            </div>
                            {/* <div className="col-sm-3">
                                
                                    <h3 className="footer-heading">Our Information</h3>
                                    <ul className="footer-nav">
                                        <li>Careers</li>
                                        <li>Blog</li>
                                        <li>Help and support</li>
                                        <li>Affiliate</li>
                                        <li>Investors</li>
                                    </ul>
                                
                            </div> */}
                            {/* <div className="col-sm-3">
                                
                                    <h3 className="footer-heading">My Account</h3>
                                    <ul className="footer-nav">
                                        <li>Press inquiries</li>
                                        <li>Social media</li>
                                        <li>directories Images</li>
                                        <li>Images & B-roll</li>
                                        <li>Permissions</li>
                                    </ul>
                                
                            </div> */}
                            <div className="">
                               
                                    <h3 className="footer-heading">Policy</h3>
                                    <ul className="footer-nav">
                                        <li><Link to="/terms" style={{textDecoration:"none",color:"white"}}>Terms</Link></li>
                                        <li><Link to="/privacy&policy" style={{textDecoration:"none",color:"white"}}>Privacy policy</Link></li>
                                        {/* <li>Cookie settings</li>
                                        <li>Site map</li>
                                        <li>Accessbility</li> */}
                                    </ul>
                               
                            </div>
                        </div>
                    </div>
                <div className="d-flex justify-content-center align-items-center footer-logo  pb-3" style={{ color: "white" }}>
                    <p>
                        <i className="fa-regular fa-copyright icon"></i> All Rights Reserved by {" "}
                        <a href="https://hminnovance.com/" target="_blank">H &amp; M Innovance LLP</a>
                    </p>

                </div>

            </footer>
        </section>

    )
}

export default Footer
