import { useState } from "react";
import "./styles/Course.css"
import Courses from "./Courses";
import SubCourses from "./SubCourses";



const CourseCategory = () => {
  const [showTab, setShowTab] = useState("hacking");

  return (
    <section className="mt-5 d-flex flex-column">
      <div className="course-section container mt-5">
        <h4 className="mt-3">All the skills you need in one place</h4>
        <p className="mt-3">
          From critical skills to technical topics, We are supporting your
          professional development.
        </p>
        <div className="course-container mt-5">
          <ul className="nav nav-tabs" id="myTab">
            <li className="nav-item cursor-pointer">
              <a
                className={`nav-link ${showTab === "hacking" ? "active" : ""}`}
                id="hacking-tab"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="hacking"
                onClick={() => setShowTab("hacking")}
                href="#hacking"
              >
                Hacking
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${showTab === "itcertifications" ? "active" : ""}`}
                id="itcertifications-tab"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="itcertifications"
                onClick={() => setShowTab("itcertifications")}
                href="#itcertifications"
              >
                IT Certifications
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${showTab === "friends" ? "active" : ""}`}
                id="friends-tab"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="friends"
                onClick={() => setShowTab("friends")}
                href="#"
              >
                Leadership
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${showTab === "photos" ? "active" : ""}`}
                id="photos-tab"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="photos"
                onClick={() => setShowTab("photos")}
                href="#"
              >
                Data Science
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${showTab === "pages" ? "active" : ""}`}
                id="photos-tab"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="pages"
                onClick={() => setShowTab("pages")}
                href="#"
              >
                Communication
              </a>
            </li>
            {/* <li className="nav-item">
              <a
                className={`nav-link ${showTab === "business" ? "active" : ""}`}
                id="photos-tab"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="business"
                onClick={() => setShowTab("business")}
                href="#"
              >
                Business Analytic & Intelligence
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="tab-content" id="myTabContent">
        <div
          className={`tab-pane fade ${
            showTab === "hacking" && "show active"
          } `}
          role="tabpanel"
        >
         {/* <Courses selectTab={showTab}/> */}
         <SubCourses myTab={showTab}/>
        </div>
        <div
          className={`tab-pane fade ${showTab === "itcertifications" && "show active"} `}
          role="tabpanel"
        >
         {/* <Courses selectTab={showTab}/> */}
         <SubCourses myTab={showTab}/>
        </div>
        <div
          className={`tab-pane fade ${showTab === "friends" && "show active"} `}
          role="tabpanel"
        >
        <SubCourses myTab={showTab}/>
        </div>
        <div
          className={`tab-pane fade ${showTab === "photos" && "show active"} `}
          role="tabpanel"
        >
          <SubCourses myTab={showTab}/>
        </div>
        <div
          className={`tab-pane fade ${showTab === "pages" && "show active"} `}
          role="tabpanel"
        >
            <SubCourses myTab={showTab}/>
        </div>
        <div
          className={`tab-pane fade ${
            showTab === "business" && "show active"
          } `}
          role="tabpanel"
        >
          <SubCourses myTab={showTab}/>
        </div>
      </div>
    </section>
  );
};

export default CourseCategory;
