import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import Header from './Components/Header';
import Footer from './Components/Footer';
import AllRoutes from './OtherSections/AllRoutes';

function App() {
  return (
    <main >
      <Header />
      <AllRoutes />
      <Footer />
    </main>
  );
}

export default App;
