import { Route, Routes } from "react-router-dom"
import Main from "../Components/Main"
import About from "./About"
import Contact from "./Contact"
import SingleProduct from "../Components/SingleProduct"
import Terms from "./Terms"
import PrivacyPolicy from "./Privacy&Policy"
import CourseCategory from "./CourseCategory"

const AllRoutes = () => {

    return (
        <Routes>
            <Route path='/' element={<Main/>}/>
            <Route path='/courses' element={<CourseCategory/>}/>
            <Route path='/about' element={<About />}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/product/:id' element={<SingleProduct />}/>
            <Route path="/terms" element={<Terms/>}/>
            <Route path="/privacy&policy" element={<PrivacyPolicy/>}/>
        </Routes>
    )
}
export default AllRoutes