import { Link } from "react-router-dom"

const CourseCard = ({ id, image, courseName, desc, rating, enrolled, price, offPrice }) => {

    return (
        <div className="card mx-1" key={id}>
            <Link to={`/product/${id}`}><img className="w-[400px] h-[150px]" src={image} alt="Card cap" /></Link>
            <div className="card-body">
                <p className="card-title">{courseName}</p>
                <p className="card-text">{desc}</p>
                <ul className='rating'>
                    <li>
                        {rating}
                        <i className="fa-solid fa-star star-icon"></i>
                        <i className="fa-solid fa-star star-icon"></i>
                        <i className="fa-solid fa-star star-icon"></i>
                        <i className="fa-solid fa-star star-icon"></i>
                        <span>({enrolled})</span>
                    </li>
                    <li className='mt-2'>₹{price} <span style={{ textDecoration: "line-through", marginLeft: "5px", fontWeight: "400" }}>{offPrice}</span></li>
                </ul>
            </div>
        </div>
    )
}

export default CourseCard