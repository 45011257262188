import { useContext, useState } from "react";
import "./styles/Header.css"
import { Link } from "react-router-dom";
import { searchContext } from "./ContextProvider";
import logo from "../Assets/logo.jpeg"
const Header = () => {
  const [isMenuActive, setMenuActive] = useState(false);
  const { searchValue, setSearchValue } = useContext(searchContext)

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value.toLowerCase()); // Ensure case-insensitive search
  };

  const closeMenu = () => {
    setMenuActive(false);
  };

  const toggleMenu = () => {
    setMenuActive(!isMenuActive);
  };

  return (
    <header className="header" id="header">
      <nav className="navbar container">
        <Link to="/">
          <img
            style={{ borderRadius: "50%", backgroundColor: "white" }}
            src={logo}
            alt="img"
            height="55px"
            width="55px"
          />
        </Link>
        <div className="search-box m-auto d-flex justify-content-between search-form">
          <input
            type="text"
            className=" search-input"
            placeholder="Search..."
            style={{ color: "black" }}
            value={searchValue}
            onChange={handleSearchChange}
          />
        </div>
        <div className="burger" id="burger" onClick={toggleMenu}>
          <span className="burger-line"></span>
          <span className="burger-line"></span>
          <span className="burger-line"></span>
        </div>
        <span className="overlay" onClick={closeMenu}></span>
        <div className={`menu ${isMenuActive ? "is-active" : ""}`}>
          <ul className="menu-inner md:flex md:flex-row md:gap-x-px" id="inner-section">
            <li className="menu-item">
              <Link
                className="menu-link d-flex home-btn"
                to="/courses"
                onClick={closeMenu}
                style={{ textDecoration: "none" }}
              >
                <div>
                  Courses
                </div>

              </Link>
            </li>
            <li className="menu-item active">
              <Link
                className=" menu-link d-flex"
                to="/about"
                onClick={closeMenu}
                style={{ textDecoration: "none" }}
              >
                <div className="friend-requests-btn nav-btn">
                  About us
                </div>

              </Link>
            </li>
            <li className="menu-item active">
              <Link className="menu-link d-flex" to="/contact" onClick={closeMenu} style={{ textDecoration: "none" }}>
                <div className="messages-btn nav-btn">
                  Contact us
                </div>

              </Link>
            </li>


          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Header