import "./styles/About.css"
import {Link} from "react-router-dom"
import React from "react"

const About = () => {
    return (
        <section className="mt-5">
            <div className="about">
                <div className="mt-5 container ">
                    <div className="hero_section mt-5 d-flex justify-content-around align-items-center">

                        <div className="hero-title">
                            <h1>Welcome to where <br /> possibilities begin</h1>
                        </div>
                        <div className="hero_title_background mt-3" style={{ backgroundImage: `url("https://about.udemy.com/wp-content/uploads/2024/02/about-homepage-hero-jan-2024.png")` }}>

                        </div>
                    </div>

                </div>
                <div className="desc d-flex justify-content-center align-items-center">
                    <strong>Check out our latest news!</strong>
                </div>

            </div>
            <div className="container desc1 mt-5">
                <div>      
                <p>We are thrilled to introduce our brand-new web portal and app, designed to enhance your learning experience. Our courses are structured into three comprehensive sections: </p>
                    <p><span className="font-semibold text-[16px]">Video Course:</span> Learn at your own pace with our expertly crafted video tutorials.</p>
                    <p><span className="font-semibold text-[16px]">Live Classes:</span> Interact directly with our instructors in real-time sessions.</p>
                    <p><span className="font-semibold text-[16px]">Training in a Company:</span> Apply your knowledge in a real-world setting, either online or at our office.
                    To participate in the company training, you must first complete the Video Course and Live Classes. The best part? You only pay for the classes you choose—no need to purchase the entire course upfront!
                    For any queries, feel free to contact us. Stay connected by joining our social media community on <Link to="https://myyearbook.live/">MyYearBook.in</Link> where we’ll keep you updated with the latest news and information.</p>
                    <p>Happy learning!</p>   
                </div>
            </div>
            <div className="container mt-5 desc1">
            <h3 className="text-center">All Categories</h3>
            <p className="mt-4">To provide a streamlined and personalized learning experience, we've organized our extensive range of courses into distinct categories. This categorization allows you to easily find the courses that match your interests and career goals. Here’s a breakdown of our current categories:</p>
            <p><span className="font-semibold text-[16px]">Information Technology (I.T.) -</span></p>
            <p><span className="font-semibold text-[16px]">Cyber Security:</span> Protect systems and data from cyber threats.</p>
            <p><span className="font-semibold text-[16px]">Hacking:</span> Learn ethical hacking techniques and security testing.</p>
            <p><span className="font-semibold text-[16px]">Linux:</span> Master the Linux operating system, from basics to advanced levels.</p>
            <p><span className="font-semibold text-[16px]">Web Development:</span> Build and design responsive websites and web applications.</p>
            <p><span className="font-semibold text-[16px]">Foreign Languages:</span> Enhance your global communication skills with our language courses.</p>
            <p>
            <span className="font-semibold text-[16px]">Basic Courses for Class 9-12:</span> Strengthen your foundational knowledge in various subjects, tailored for high school students.In addition to these categories, we offer a wide range of uncategorized courses, ensuring there’s something for every student. Whether you're looking to explore new subjects or specialize in a particular field, you'll find the right course for you.We are continually expanding our offerings, with many more courses to be added soon. This structure ensures that you can easily navigate our platform and focus on the subjects that are most relevant to your learning journey.
            </p>
            </div>
            <div className="container mt-5 desc1">
                <h2 className="text-center">Skills are the key to unlocking potential</h2>
                <p className="text-center mt-3 pb-4">Whether you want to learn a new skill, train your teams, or share what you know with the world,<br /> you’re in the right place. As a leader in online learning, we’re here to help you achieve your <br /> goals and transform your life.</p>
            </div>
            <div className="create-impact">
                <div className="container mt-3">
                    <h2 className="text-center">Creating impact around the world</h2>
                    <p className="text-center mt-3" style={{ fontWeight: "500" }}> With our global catalog spanning the latest skills and topics, people and organizations,<br /> everywhere are able to adapt to change and thrive.</p>
                    {/* <div className="d-flex justify-content-around mt-4 total">
                        <div className="d-flex justify-content-between">
                            <ul>
                                <li style={{ fontSize: "40px", fontWeight: "700" }}>69M</li>
                                <li className="text-center" >Learners</li>
                            </ul>
                            <ul>
                                <li style={{ fontSize: "40px", fontWeight: "700" }}>75</li>
                                <li className="text-center">Languages</li>
                            </ul>
                            <ul>
                                <li style={{ fontSize: "40px", fontWeight: "700" }}>75+</li>
                                <li className="text-center">Instructors</li>
                            </ul>
                        </div>
                        <div className="d-flex justify-content-between">
                            <ul>
                                <li style={{ fontSize: "40px", fontWeight: "700" }}>69M</li>
                                <li className="text-center">Learners</li>
                            </ul>
                            <ul>
                                <li style={{ fontSize: "40px", fontWeight: "700" }}>75</li>
                                <li className="text-center">Languages</li>
                            </ul>
                            <ul>
                                <li style={{ fontSize: "40px", fontWeight: "700" }}>75+</li>
                                <li className="text-center">Instructors</li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="container mt-5">
                <div className="d-flex justify-content-evenly align-items-center pb-4 blog-section">
                    <div>
                        <h3 style={{ fontWeight: "700", borderTop: "8px solid #A435F0", paddingTop: "20px" }}>Work with us</h3>
                        {/* <p >Lorem ipsum dolor sit amet consectetur <br /> adipisicing elit. Debitis, fugit. Temporibus <br />  voluptatibus architecto velit autem fugiat <br />  quibusdam exercitationem itaque consectetur.</p> */}
                        <p>contact@hminnovance for resume</p>
                        <p style={{ color: "#A435F0", fontWeight: "500" }}>Join our team <i className="fa-solid fa-angle-right icon"></i></p>
                    </div>
                    {/* <div>
                        <h3 style={{ fontWeight: "700", borderTop: "8px solid #5624D0", paddingTop: "20px" }}>See our research</h3>
                        <p >Lorem ipsum dolor sit amet consectetur <br /> adipisicing elit. Debitis, fugit. Temporibus <br />  voluptatibus architecto velit autem fugiat <br />  quibusdam exercitationem itaque consectetur.</p>
                        <p style={{ color: "#5624D0", fontWeight: "500" }}>Learn more <i className="fa-solid fa-angle-right icon"></i></p>
                    </div> */}
                    <div>
                        <h3 style={{ fontWeight: "700", borderTop: "8px solid #199FA3", paddingTop: "20px" }}>Visit our website</h3>
                        {/* <p>Lorem ipsum dolor sit amet consectetur <br /> adipisicing elit. Debitis, fugit. Temporibus <br />  voluptatibus architecto velit autem fugiat <br />  quibusdam exercitationem itaque consectetur.</p> */}
                        <p><Link to="https://myyearbook.live/" style={{textDecoration:"none"}}>MyYearBook.in</Link></p>
                        <p style={{ color: "#199FA3", fontWeight: "500" }}>click now <i className="fa-solid fa-angle-right icon"></i></p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About