import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import "./styles/Main.css"
import img1 from "../Assets/img1.jpg"
import img2 from "../Assets/img2.jpg"
import img3 from "../Assets/img3.jpg"
import { productData } from '../constant/Product';
import { useContext, useEffect, useState } from 'react';
import { searchContext } from './ContextProvider';
import CourseCard from './CourseCard';
import { popularCourse } from '../constant/popularCourse';
const Main = () => {
    const [filteredProducts, setFilteredProducts] = useState(popularCourse);
    const { searchValue } = useContext(searchContext)

    useEffect(() => {
        const filteredData = popularCourse.filter((product) => {
            // Case-insensitive search using toLowerCase()
            const searchTerm = searchValue.toLowerCase();
            const courseNameLower = product.courseName.toLowerCase();
            return courseNameLower.includes(searchTerm);
        });
        setFilteredProducts(filteredData);
    }, [searchValue]); // Dependency on searchValue to update filteredProducts

    return (
        <section className='mt-5'>

            <div className='slider-section'>

                <Swiper
                    pagination={{
                        type: 'progressbar',
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper "
                >
                    <SwiperSlide><img src={img1} className='set_img' alt='slider'/></SwiperSlide>
                    <SwiperSlide><img src={img2} className='set_img' alt='slider'/></SwiperSlide>
                    <SwiperSlide><img src={img3} className='set_img' alt='slider'/></SwiperSlide>

                </Swiper>
            </div>

            <div className='popolar-courses container mt-5 mb-5'>
                <h3 className='text-left mx-1'>Popular Courses</h3>
                <div className='mt-3 d-flex justify-content-between card-section'>
                    {filteredProducts.length > 0 ? (
                        filteredProducts.map((product) => (
                            <CourseCard id={product.id} image={product.image} 
                            courseName={product.courseName} desc={product.desc} 
                            rating={product.rating}
                            enrolled={product.enrolled}
                            price={product.price}
                            offPrice={product.offPrice}
                            />
                        ))
                    ) : (
                        <p className='mx-1'>No courses found matching your search.</p>
                    )}

                </div>
            </div>
            {/* <div className='testmonial'>
                <div className='container mt-5 test-body' style={{ paddingTop: "45px", paddingBottom: "45px" }}>
                    <h4 className='max-1 mb-5'>See what others are achieving through learning</h4>
                    <div className=' d-flex justify-content-between card-section'>
                        <div className="card mx-1 " >

                            <div className="card-body">
                                <img src="https://s.udemycdn.com/browse_components/student-quote-unit/quote.svg" alt="" width="17.78" height="16" loading="lazy" className='mt-2' />
                                <p className='mt-3'>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, iusto. Aliquid omnis repudiandae distinctio quidem labore laboriosam reprehenderit itaque debitis?
                                </p>
                                <div className='d-flex justify-content-evenly'>
                                    <img className="card-img-top" src="https://img.freepik.com/free-photo/beautiful-young-man-student-businessman-jacket-holds-his-arms-crossed-isolated-light-grey-wall_231208-6135.jpg" alt="Card cap" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
                                    <ul>
                                        <li>Alvin Lim</li>
                                        <li>Technical Co-Founder, CTO at</li>
                                        <li>Dimensional</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className="card mx-1 " >

                            <div className="card-body">
                                <img src="https://s.udemycdn.com/browse_components/student-quote-unit/quote.svg" alt="" width="17.78" height="16" loading="lazy" className='mt-2' />
                                <p className='mt-3'>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, iusto. Aliquid omnis repudiandae distinctio quidem labore laboriosam reprehenderit itaque debitis?
                                </p>
                                <div className='d-flex justify-content-evenly'>
                                    <img className="card-img-top" src="https://t3.ftcdn.net/jpg/04/98/01/20/360_F_498012011_8t7qs4duQLJ0ih1h1xVygTKzfwfxO7UE.jpg" alt="Card cap" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
                                    <ul>
                                        <li>Alvin Lim</li>
                                        <li>Technical Co-Founder, CTO at</li>
                                        <li>Dimensional</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card mx-1 " >

                            <div className="card-body">
                                <img src="https://s.udemycdn.com/browse_components/student-quote-unit/quote.svg" alt="" width="17.78" height="16" loading="lazy" className='mt-2' />
                                <p className='mt-3'>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, iusto. Aliquid omnis repudiandae distinctio quidem labore laboriosam reprehenderit itaque debitis?
                                </p>
                                <div className='d-flex justify-content-evenly'>
                                    <img className="card-img-top" src="https://insertface.com/fb/1078/professional-outfit-men-1077867-e7t3n-fb.jpg" alt="Card cap" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
                                    <ul>
                                        <li>Alvin Lim</li>
                                        <li>Technical Co-Founder, CTO at</li>
                                        <li>Dimensional</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card mx-1 " >

                            <div className="card-body">
                                <img src="https://s.udemycdn.com/browse_components/student-quote-unit/quote.svg" alt="" width="17.78" height="16" loading="lazy" className='mt-2' />
                                <p className='mt-3'>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, iusto. Aliquid omnis repudiandae distinctio quidem labore laboriosam reprehenderit itaque debitis?
                                </p>
                                <div className='d-flex justify-content-around'>
                                    <img className="card-img-top" src="https://img.freepik.com/premium-photo/photo-smiling-young-male-professional-standing-with-arms-crossed-while-making-eye-contact_925414-13085.jpg" alt="Card cap" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
                                    <ul>
                                        <li>Alvin Lim</li>
                                        <li>Technical Co-Founder, CTO at</li>
                                        <li>Dimensional</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

        </section>
    )
}

export default Main